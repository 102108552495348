document.addEventListener("turbolinks:load", async() => {
  const container = document.getElementById("statusScreenBrick_container");
  if(container){
    const storeId = document.getElementById("statusScreenBrick_container").dataset.storeId
    const cartId = document.getElementById("statusScreenBrick_container").dataset.cartId
    const public_key = document.getElementById('statusScreenBrick_container').dataset.publicKey 
    // const public_key = 'TEST-ee472852-415a-41a1-8661-54cd0924fa15' // test

    const mp = new MercadoPago(public_key, { locale: 'es-CL'})

    const queryParams = new URLSearchParams(window.location.search)
    const paymentId = queryParams.get('payment_id')
    const externalResource = queryParams.get('external_resource_url')
    const creq = queryParams.get('creq')

    async function loadPaymentForm() {
      const settings = {
        initialization: {
          paymentId: paymentId,
          additionalInfo: {
          externalResourceURL: externalResource,
          creq: creq,
          }
        },
        callbacks: {
            onReady: () => {
                console.log('brick ready')
            },
            onError: (error) => {
                console.log(error)
            },
        },
        customization: {
          visual: {
            showExternalReference: true,
            texts:{ ctaReturnLabel: 'Ir a mis compras' }
          },
          backUrls: {
            error: `https://included-assured-opossum.ngrok-free.app/stores/${storeId}/carts/${cartId}`,
            return: 'https://included-assured-opossum.ngrok-free.app/accounts/sales'
          }
        }
      };
        
      const bricksBuilder = mp.bricks();
      window.statusScreenBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
    };

    console.log('Payment ID:', paymentId);
    console.log('External Resource URL:', externalResource);
    console.log('Creq:', creq);
    console.log('Public key', public_key)

    function unmountBrickResponse() {
      if (window.statusScreenBrickController) {
        window.statusScreenBrickController.unmount()
        window.statusScreenBrickController = null;
        console.log('Brick_response unmounted');
      }
    }

    document.addEventListener('turbolinks:before-render', unmountBrickResponse)
    window.addEventListener('beforeunload', unmountBrickResponse)

    await loadPaymentForm();
  }
});